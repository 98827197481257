.gest {
  width: 350px;
}
@media screen and (max-width: 800px) {
  .gest {
    height: 200px;
    margin: auto;
  }
  @media (max-width: 400px) {
    .gest {
      max-width: 280px;
      height: 270px;
    }
  }
}
