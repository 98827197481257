@import '../../variables.scss';

#options {
  width: 380px;
  height: 300px;
  margin-right: -500px;
}

.opt {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: ($primary-inverse);
  transition: transform 400ms, background 200ms;
  transform: rotate(45deg);
  border-radius: 20px;
  cursor: pointer;
  box-shadow: 0 2px 44px rgba($primary-color, 0.5);
  border: 1px solid #6d6d6d59;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.opt:hover {
  background: rgb(224, 224, 224);
}

.opt.active {
  transform: rotate(45deg) scale(1.1);
  background: ($primary-color);
}

.opt.opt-0 {
  width: 150px;
  height: 150px;
  top: 50px;
  left: 40px;
}

.opt.opt-1 {
  width: 75px;
  height: 75px;
  top: 25px;
  left: 190px;
  border-radius: 10px;
}

.opt.opt-2 {
  width: 150px;
  height: 150px;
  top: 100px;
  left: 220px;
}
.opt.opt-3 {
  width: 75px;
  height: 75px;
  top: 200px;
  left: 145px;
  border-radius: 10px;
}
.opt > img {
  max-width: 75%;
  max-height: 75%;
  transform: rotate(-45deg);
}
@media (max-width: 801px) {
  #options {
    max-width: 400px;
    position: relative;
    margin: auto;
  }
}
@media (max-width: 420px) {
  #options {
    max-width: 320px;
    position: relative;
  }
  .opt.opt-0 {
    width: 130px;
    height: 130px;
    top: 50px;
    left: 30px;
  }
  .opt.opt-1 {
    width: 65px;
    height: 65px;
    top: 28px;
    left: 160px;
  }
  .opt.opt-2 {
    width: 130px;
    height: 130px;
    top: 100px;
    left: 180px;
  }
  .opt.opt-3 {
    width: 65px;
    height: 65px;
    top: 185px;
    left: 115px;
  }
}
@media (max-width: 375px,) {
  #options {
    max-width: 320px;
    position: relative;
  }
  .opt.opt-0 {
    width: 130px;
    height: 130px;
    top: 50px;
    left: 10px;
  }
  .opt.opt-1 {
    width: 65px;
    height: 65px;
    top: 28px;
    left: 140px;
  }
  .opt.opt-2 {
    width: 130px;
    height: 130px;
    top: 100px;
    left: 160px;
  }
  .opt.opt-3 {
    width: 65px;
    height: 65px;
    top: 185px;
    left: 93px;
  }
}
